<template>
  <div :class="{ contain: true, mobile: isMobile }">
      <div class="setting d-flex flex-row">
    
        <div class="right  mt-1" >
              <div class="top_tab ">
             <span class="btn "  :class="{ btn2: ownerClick }" @click="mode_click('management')"
            >{{ $t("Referral_management") }}</span
            > 
             <span class="btn" style="margin-left:116px" :class="{ btn2: soldClick }" @click="mode_click('fans')"
            >{{ $t("fansSubscrible") }}</span
          > 
            <span class="btn " style="margin-left:116px" :class="{ btn2: folderClick }"  @click="mode_click('myFource')"
            >{{ $t("avatarAvatarInfoFans") }}</span
          >       
        </div>
        </div>
          
       
      </div>
      <ReferralManagement v-if="ownerClick"/>
      <Fans v-if="folderClick"/>
      <Follows  v-if="soldClick"/>
    <uComponents ref="ucom"></uComponents>
  </div>
</template>

<script>

import Fans from "./fans";
import Follows from "./follows";
import ReferralManagement from "./referralManagement";
export default {
  components: {Fans,Follows,ReferralManagement },
  data: function () {
    return {
        isloadList:false,
        isShowLoading: false,
        pageNumber: 1,
        pageSize: 10,
        totalPage: 0,
        folderClick:false,
        soldClick : false,
        ownerClick:true,
        clickMode:"management",
       
     
    };
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  async mounted() {
      window.eventBus.$on("LangChange", this.onLangChange);
      console.log("wxl --- toMyFans",this.$route.params.toMyFans)
      if(this.$route.params.toMyFans){
        this.mode_click('myFource')
      }
   
  },
 
  methods: {
   mode_click(mode) {             
              this.showStatus(mode);
        },
  showStatus(mode){
                this.clickMode = mode
                if(mode == "management"){
                    this.soldClick = false;
                    this.ownerClick = true;
                    this.folderClick = false;
                }else if(mode == "fans"){
                  
                    this.soldClick = true;
                    this.ownerClick = false;
                        this.folderClick = false;
                }else if(mode == "myFource"){
                    this.soldClick = false;
                    this.ownerClick = false;
                    this.folderClick = true;
                  
                }
  }
  },

 
};
</script>

<style lang="scss" scoped>
.contain {
     margin: 0;
  padding: 0;
   .setting{ 
       .right{
            .top_tab {
                width: 950px;
        .btn {
          height: 25px;
          font-family:Helvetica;
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 40px;
          letter-spacing: 0px;
          color: #766983;
          cursor:pointer;
        }
        .btn2 {
          height: 25px;
          font-family:Helvetica;
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 40px;
          letter-spacing: 0px;
         color: #270645;
          border-bottom: 3px solid #270645;
          padding-bottom: 8px;
        }
      }
              .setTitle{
            font-family: Helvetica;
            font-size: 28px;
            font-weight: bold;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #270645;
       }
        .description{
            font-family: Helvetica;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #270645;
        }
       
      
       

              
       }
    
       
   }
   
   
   
   
 &.mobile{
    .setting{
    .left{

    }
    .right{
         .list{
        .settingList{
            .settingItem{
                width: 100%;
                .clickItem{
                .value{ 
                    font-size: 15px;
              
                }
                .valueDes{
                    font-size: 13px;
                    
                }
                

            }
            }
        }
    }
    .footer {
        margin: 30px 26px 80px;
        .sub{
            width: 280px;
        }
    }
    }
    
    }

    }
  
}
</style>
